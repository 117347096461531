import PageLayout from "../../../components/PageLayout/PageLayout";
import UpdateSAPSystemForm from "./UpdateSystemForm/UpdateSAPSystemForm";
import useUpdateSystem from "./logic/useUpdateSystem";

const UpdateCortexFloxSystem = () => {
  const { isLoading, error, onSubmit, register, handleSubmit, isSubmitted, errors, data, handleAddCategoryClick, showSlideOver,
    setShowSlideOver, setAddedCategory, categoryData, categoryError, categoryIsLoading } = useUpdateSystem();

  return (
    <PageLayout pageName="Update Connections" pageClass="updateSystemPage" redirectBack='/cortex/connections'>
      <UpdateSAPSystemForm
        isLoading={isLoading}
        error={error}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        isSubmitted={isSubmitted}
        errors={errors}
        data={data}
        handleAddCategoryClick={handleAddCategoryClick}
        showSlideOver={showSlideOver}
        setShowSlideOver={setShowSlideOver}
        setAddedCategory={setAddedCategory}
        categoryData={categoryData}
        categoryError={categoryError}
        categoryIsLoading={categoryIsLoading}
      />
    </PageLayout>
  );
};

export default UpdateCortexFloxSystem;