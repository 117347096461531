import React, { useCallback, useRef, useState } from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TransparentButton from "../../../../components/Buttons/TransparentButton";
import { IconBookMark, IconCheck, IconRemoveBookMark, IconTestCnx, IconX } from "../../../../components/_Catalog/icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import InputField from "../../../../components/AppInputForm/InputField/InputField";
import { toast } from 'react-toastify';

const BoomiSystemForm = ({
    onSubmit,
    register,
    handleSubmit,
    isLoading,
    reset,
    setSelectedConnectionType,
    selectedConnectionType,
    testConnectionStatus,
    navigateTo,
    isSubmitted,
    errors
}) => {
    const formRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const SaveData = () => {
        const formData = new FormData(formRef.current);
        const dataToSave = {
            name: formData.get("name"),
            description: formData.get("description"),
            atom_id: formData.get("atom_id"),
            account_id: formData.get("account_id"),
            email: formData.get("email"),
            password: formData.get("password"),
        };
        localStorage.setItem("connectionData", JSON.stringify(dataToSave));
        toast.success("Connection details saved");
    };

    const clearData = useCallback(() => {
        localStorage.removeItem("connectionData");
        reset({
            name: "",
            description: "",
            atom_id: "",
            account_id: "",
            email: "",
            password: "",
        });
        setSelectedConnectionType("OS");
        toast.success("Connection details removed");
    }, [reset, setSelectedConnectionType]);

    const connectionData = JSON?.parse(localStorage.getItem("connectionData"));

    return (
        <div className="md:col-span-2 md:mt-0">
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="overflow-x-auto max-h-[70vh] rounded-md border sm:rounded-md custom-scrollbar">
                    <div className="sticky bg-[#FFFFFF] dark:bg-secondary-bg-color z-10 top-0">
                        <label htmlFor="type" className="block ml-6 pt-4 text-sm font-medium text-app-gray-700">
                            Connection type
                        </label>
                        <div className="flex justify-between mx-6 pb-3 border-b">
                            <div>
                                <select
                                    className="w-24 mt-1 block rounded-md border border-app-gray-300 shadow-sm focus:border-app-gray-300 
                  focus:ring-indigo-500 sm:text-sm text-app-gray-700 pr-2"
                                    value={selectedConnectionType}
                                    onChange={(e) => setSelectedConnectionType(e.target.value)}
                                >
                                    <option value="SAP">SAP</option>
                                    <option value="OS">OS</option>
                                    <option value="Boomi">Boomi</option>

                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="px-4 py-3 sm:p-6 bg-transparent-dark">
                        <div className="pt-6 px-6 mb-3 border rounded-lg">
                            <span className="mb-4 block text-xl text-app-gray-700">Connection Details</span>
                            <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                                <InputField
                                    name="name"
                                    label="Connection Name"
                                    placeholder="Enter name"
                                    defaultValue={connectionData?.name || ""}
                                    errors={isSubmitted && errors.name}
                                    disabled={false}
                                    register={register}
                                />
                                <InputField
                                    name="description"
                                    label="Description"
                                    placeholder="Enter description"
                                    defaultValue={connectionData?.description || ""}
                                    disabled={false}
                                    register={register}
                                    errors={isSubmitted && errors.description}
                                />

                                <InputField
                                    name="atom_id"
                                    label="AtomId"
                                    placeholder="Enter host"
                                    defaultValue={connectionData?.atom_id || ""}
                                    disabled={false}
                                    register={register}
                                    errors={isSubmitted && errors.atom_id}
                                />
                                <InputField
                                    name="account_id"
                                    label="AccountId"
                                    placeholder="Enter port"
                                    defaultValue={connectionData?.account_id || ""}
                                    disabled={false}
                                    register={register}
                                    errors={isSubmitted && errors.account_id}
                                />
                                <InputField
                                    name="email"
                                    label="Email"
                                    placeholder="Enter user"
                                    defaultValue={connectionData?.email || ""}
                                    disabled={false}
                                    register={register}
                                    errors={isSubmitted && errors.email}
                                />
                                <div className="relative col-span-3">
                                <InputField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter password"
                                    defaultValue={connectionData?.password || ""}
                                    disabled={false}
                                    register={register}
                                    errors={isSubmitted && errors.password}
                                />
                                <span
                                    className="absolute inset-y-0 right-2 top-9 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex sticky bottom-0 justify-between gap-3 bg-[#F7F7F7] dark:bg-[#1f3047] px-4 py-3 sm:px-6">
                        <div className="flex gap-x-4">
                            <TransparentButton isLoading={isLoading} buttonText="Save" iconButton={<IconBookMark />} buttonEvent={SaveData} />
                            <TransparentButton isLoading={isLoading} buttonText="Clear" iconButton={<IconRemoveBookMark />} buttonEvent={clearData} />
                        </div>
                        <div className="flex gap-x-4">
                            <TransparentButton buttonEvent={() => navigateTo('/cortex/connections')} buttonText="Cancel" iconButton={<IconX sm color="var(--secondary-color)" />} />
                            {(testConnectionStatus === true || testConnectionStatus === undefined) ? <PrimaryButton type="submit" isLoading={isLoading} buttonText="Submit" iconButton={<IconCheck />} /> : <PrimaryButton type="submit" isLoading={isLoading} buttonText="Test Connection" iconButton={<IconTestCnx />} />}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BoomiSystemForm;