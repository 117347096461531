import PageLayout from "../../../components/PageLayout/PageLayout";
import SAPSystemDetailsForm from "./SystemDetailsForm/SAPSystemDetailsForm";
import OSSystemDetailsForm from "./SystemDetailsForm/OSSystemDetailsForm";
import BoomiSystemDetailsForm from "./SystemDetailsForm/BoomiSystemDetailsForm";
import useSystemDetails from "./logic/useSystemDetails";
import { IconSpinner } from "../../../components/_Catalog/icons";

const CortexFlowSystemDetails = () => {
  const { isLoading, data, error, selectedSystem, setSelectedSystem } = useSystemDetails();

  return (
    <PageLayout pageName="Connection Details" pageClass="systemDetailsPage" redirectBack='/cortex/connections'>
      {isLoading ? (
        <div className="animate-spin h-10 w-10 mr-2 m-auto">
          <IconSpinner />
        </div>
      ) : error ? (
        <div className="text-2xl text-app-red-color">{error.message}</div>
      ) : selectedSystem === "SAP" ? (
        <SAPSystemDetailsForm
          isLoading={isLoading}
          data={data}
          error={error}
          selectedSystem={selectedSystem}
          setSelectedSystem={setSelectedSystem}
        />
      ) : selectedSystem === "OS" ? (
        <OSSystemDetailsForm
          isLoading={isLoading}
          data={data}
          error={error}
          selectedSystem={selectedSystem}
          setSelectedSystem={setSelectedSystem}
        />
      ) : selectedSystem === "Boomi" && (
        <BoomiSystemDetailsForm
          isLoading={isLoading}
          data={data}
          error={error}
          selectedSystem={selectedSystem}
          setSelectedSystem={setSelectedSystem}
        />
      )}

    </PageLayout>
  );
};

export default CortexFlowSystemDetails;
