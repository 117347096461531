import React, { useRef, useState } from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TransparentButton from "../../../../components/Buttons/TransparentButton";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IconCheck, IconTestCnx, IconX } from "../../../../components/_Catalog/icons";
import InputField from "../../../../components/AppInputForm/InputField/InputField";

const OSSystemForm = ({
  onSubmit,
  register,
  handleSubmit,
  isLoading,
  setSelectedConnectionType,
  selectedConnectionType,
  testConnectionStatus,
  navigateTo,
  isSubmitted,
  errors
}) => {
  const formRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="md:col-span-2 md:mt-0">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="overflow-x-auto max-h-[70vh] rounded-md border sm:rounded-md custom-scrollbar">
          <div className="sticky bg-[#FFFFFF] dark:bg-secondary-bg-color z-10 top-0">
            <label htmlFor="type" className="block ml-6 pt-4 text-sm font-medium text-app-gray-700">
              Connection type
            </label>
            <div className="flex justify-between mx-6 pb-3 border-b">
              <div>
                <select
                  className="w-24 mt-1 block rounded-md border border-app-gray-300 shadow-sm focus:border-app-gray-300 
                  focus:ring-indigo-500 sm:text-sm text-app-gray-700 pr-2"
                  value={selectedConnectionType}
                  onChange={(e) => setSelectedConnectionType(e.target.value)}
                >
                  <option value="SAP">SAP</option>
                  <option value="OS">OS</option>
                  <option value="Boomi">Boomi</option>

                </select>
              </div>
            </div>
          </div>

          <div className="px-4 py-3 sm:p-6 bg-transparent-dark">
            <div className="pt-6 px-6 mb-3 border rounded-lg">
              <span className="mb-4 block text-xl text-app-gray-700">Connection Details</span>
              <div className="grid grid-cols-6 gap-6 mt-2 mb-6">
                <InputField
                  name="name"
                  label="Connection Name"
                  placeholder="Enter name"
                  errors={isSubmitted && errors.name}
                  disabled={false}
                  register={register}
                />
                <InputField
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  disabled={false}
                  register={register}
                  errors={isSubmitted && errors.description}
                />
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="provider" className="block text-sm font-medium text-app-gray-700">Provider</label>
                  <select
                    id="provider"
                    name="provider"
                    className="mt-1 block w-full rounded-md border border-app-gray-300 shadow-sm focus:border-app-gray-500 focus:ring-app-gray-500 sm:text-sm"
                    {...register("provider", { required: true })}
                  >
                    <option value="AWS">AWS</option>
                    <option value="Azure">Azure</option>
                    <option value="GCP">GCP</option>
                    <option value="On-Prem">On-Prem</option>
                  </select>
                  {errors.provider && <span className="text-red-500 text-xs">This field is required</span>}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="os_type" className="block text-sm font-medium text-app-gray-700">OS Type</label>
                  <select
                    id="os_type"
                    name="os_type"
                    className="mt-1 block w-full rounded-md border border-app-gray-300 shadow-sm focus:border-app-gray-500 focus:ring-app-gray-500 sm:text-sm"
                    {...register("os_type", { required: true })}
                  >
                    <option value="Linux">Linux</option>
                    <option value="Windows">Windows</option>
                  </select>
                  {errors.os_type && <span className="text-red-500 text-xs">This field is required</span>}
                </div>

                <InputField
                  name="host"
                  label="Host"
                  placeholder="Enter host"
                  disabled={false}
                  register={register}
                  errors={isSubmitted && errors.host}
                />
                <InputField
                  name="port"
                  label="Port"
                  placeholder="Enter port"
                  disabled={false}
                  register={register}
                  errors={isSubmitted && errors.port}
                />
                
                <InputField
                  name="user"
                  label="User"
                  placeholder="Enter user"
                  disabled={false}
                  register={register}
                  errors={isSubmitted && errors.user}
                />
                <div className="relative col-span-3">
                <InputField
                  name="password"
                  label="Password"
                  type="text"
                  placeholder="Enter password"
                  disabled={false}
                  register={register}
                  errors={isSubmitted && errors.password}
                />
                  <span
                    className="absolute inset-y-0 right-2 top-9 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex sticky bottom-0 justify-between gap-3 bg-[#F7F7F7] dark:bg-[#1f3047] px-4 py-3 sm:px-6">
            <div className="flex justify-end gap-x-4 w-full">
              <TransparentButton buttonEvent={() => navigateTo('/cortex/connections')} buttonText="Cancel" iconButton={<IconX sm color="var(--secondary-color)" />} />
              {(testConnectionStatus === true || testConnectionStatus === undefined) ? <PrimaryButton type="submit" isLoading={isLoading} buttonText="Submit" iconButton={<IconCheck />} /> : <PrimaryButton type="submit" isLoading={isLoading} buttonText="Test Connection" iconButton={<IconTestCnx />} />}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OSSystemForm;