import PageLayout from "../../../components/PageLayout/PageLayout";
import UpdateOSSystemForm from "./UpdateOSSystemForm/UpdateOSSystemForm";
import useUpdateOSSystem from "./logic/useUpdateOSSystem";

const UpdateOSCortexFlowSystem = () => {
  const { isLoading, error, onSubmit, register, handleSubmit, isSubmitted, errors, data } = useUpdateOSSystem();

  return (
    <PageLayout pageName="Update Connections" pageClass="updateOSSystemPage" redirectBack='/cortex/connections'>
      <UpdateOSSystemForm
        isLoading={isLoading}
        error={error}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        isSubmitted={isSubmitted}
        errors={errors}
        data={data}
      />
    </PageLayout>
  );
};

export default UpdateOSCortexFlowSystem;