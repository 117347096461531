import React from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TransparentButton from "../../../../components/Buttons/TransparentButton";
import InputField from "../../../../components/AppInputForm/InputField/InputField";
import SelectField from "../../../../components/AppInputForm/SelectField/SelectField";
import { IconAdd, IconCheck, IconX } from "../../../../components/_Catalog/icons";
import { Link } from "react-router-dom";
import AddCategory from "../../AddSystem/AddCategory/AddCategory";

const UpdateSystemForm = ({
  data,
  onSubmit,
  register,
  handleSubmit,
  isLoading,
  isSubmitted,
  errors,
  handleAddCategoryClick,
  showSlideOver,
  setShowSlideOver,
  setAddedCategory,
  categoryData, 
  categoryError, 
  categoryIsLoading,
  error
}) => {
  
  if (error) return <div className="text-2xl text-app-red-color text-center">{error}</div>
  return (
    <div className="md:col-span-2 md:mt-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="overflow-x-auto max-h-[82vh] rounded-lg border bg-transparent-dark custom-scrollbar">
          <div className="sticky bg-[#ffff] dark:bg-secondary-bg-color z-10 top-0 text-primary-color">
            <div className="flex justify-end mx-6 py-4">
              <TransparentButton buttonEvent={handleAddCategoryClick} buttonText="Add Category" iconButton={<IconAdd color="var(--primary-text-color)"/>} />
            </div>
          </div>
          <div className={"px-4  sm:px-6"}>
            <div className="px-6 pb-6 border-b mb-3">
              <span className="pb-4 block text-xl text-app-gray-700">Connection Details</span>
              <div className="  grid grid-cols-6 gap-6 mt-2 mb-6">
                <InputField
                  name="sid"
                  label="SID"
                  placeholder="Enter SID"
                  defaultValue={data?.sid}
                  errors={isSubmitted && errors.sid}
                  register={register}
                  isLoading={isLoading}
                />
                <InputField
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  defaultValue={data?.description}
                  errors={isSubmitted && errors.description}
                  register={register}
                  isLoading={isLoading}
                />
                <SelectField
                  name="environment"
                  label="Environment"
                  defaultValue={data?.system_env_id}
                  options={[{ "value": data?.system_env_id, "label": data?.system_env_name }]}
                  errors={isSubmitted && errors.environment}
                  register={register}
                  isLoading={isLoading}
                />
                <SelectField
                  name="category"
                  label="Category"
                  options={categoryData}
                  defaultValue={data?.system_category_name}
                  error={categoryError}
                  errors={isSubmitted && errors.category}
                  register={register}
                  isLoading={isLoading || categoryIsLoading}
                />
                <InputField
                  name="client"
                  label="Client"
                  type="number"
                  placeholder="Enter client"
                  defaultValue={data?.client}
                  errors={isSubmitted && errors.client}
                  register={register}
                  isLoading={isLoading}
                />
                <SelectField
                  name="language"
                  label="Language"
                  options={[
                    { value: "en", label: "English" },
                    { value: "jp", label: "Japanese" }
                  ]}
                  defaultValue="en"
                  errors={isSubmitted && errors.language}
                  register={register}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className="px-6 pb-6 border-b mb-3">
              <span className="my-4 block text-xl text-app-gray-700">Instance details</span>
              <div className="  grid grid-cols-6 gap-6 mt-2 mb-6">
                {isLoading ? <React.Fragment>
                  <InputField
                    label="Instance Number"
                    isLoading={isLoading}
                  />
                  <InputField
                    label="Instance Host"
                    isLoading={isLoading}
                  />
                  <InputField
                    label="Instance description"
                    isLoading={isLoading}
                  />
                </React.Fragment> :
                  data?.instances.map((instance, index) => (
                    <React.Fragment key={index}>
                      <input hidden defaultValue={instance.instance_id} {...register("instanceId")} />
                      <InputField
                        name={"instanceNumber"}
                        label="Instance Number"
                        type="number"
                        defaultValue={instance.instance_number}
                        placeholder="Enter instance number"
                        errors={isSubmitted && errors.instanceNumber}
                        register={register}
                        isLoading={isLoading}
                      />
                      <InputField
                        name={"instanceHost"}
                        label="Instance Host"
                        defaultValue={instance.host}
                        placeholder="Enter instance host"
                        errors={isSubmitted && errors.instanceHost}
                        register={register}
                        isLoading={isLoading}
                      />
                      <InputField
                        name={"instanceDescription"}
                        label="Instance description"
                        defaultValue={instance.description}
                        placeholder="Enter instance description"
                        errors={isSubmitted && errors.instanceDescription}
                        register={register}
                        isLoading={isLoading}
                      />
                    </React.Fragment>
                  ))}
              </div>
            </div>
            <div className="px-6 pb-6">
              <span className="my-4 block text-xl text-app-gray-700">User credentials</span>
              <div className="  grid grid-cols-6 gap-6 mt-2 mb-6">
                {isLoading ? <React.Fragment>
                  <InputField
                    label="Username"
                    isLoading={isLoading}
                  />
                  <InputField
                    label="Password"
                    isLoading={isLoading}
                  />
                  <SelectField
                    label="User Type"
                    isLoading={isLoading}
                  />
                </React.Fragment> :
                  data?.system_credentials.map((credentials, index) => (
                    <React.Fragment key={index}>
                      <input hidden defaultValue={credentials.basis_user_id} {...register("basisUserId")} />
                      <InputField
                        name={"username"}
                        label="Username"
                        defaultValue={credentials.username}
                        placeholder="Enter username"
                        errors={isSubmitted && errors.username}
                        register={register}
                        isLoading={isLoading}
                      />
                      <InputField
                        name={"password"}
                        label="Password"
                        type="password"
                        defaultValue={credentials.password}
                        placeholder="Enter password"
                        errors={isSubmitted && errors.password}
                        register={register}
                        isLoading={isLoading}
                      />
                      <SelectField
                        name="credentials.user_type"
                        label="User Type"
                        defaultValue={credentials?.user_type}
                        options={[{ "value": credentials?.user_type, "label": credentials?.user_type }]}
                        errors={isSubmitted && errors.user_type}
                        register={register}
                        isLoading={isLoading}
                      />
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex sticky bottom-0 flex-row-reverse gap-3 bg-[#F7F7F7] dark:bg-secondary-bg-color px-4 py-3 sm:px-6">
            <PrimaryButton type="submit" isLoading={isLoading} buttonText="Update" iconButton={<IconCheck />} />
            <Link to="/cortex/connections"><TransparentButton buttonText="Cancel" iconButton={<IconX sm color="var(--secondary-color)" />} /></Link>
          </div>
        </div>
      </form>
      {showSlideOver && <AddCategory showSlideOver={showSlideOver} setShowSlideOver={setShowSlideOver} setAddedCategory={setAddedCategory} />}
    </div>
  );
};

export default UpdateSystemForm;